* {
  box-sizing: border-box;
}

.form {
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}

.description {
  background-color: #EEEEEE;
  font-size: 18px;
  width: 50%;
}

.description.isEmpty {
  display: flex;
  justify-content: center;
  align-items: center;
}

.description__empty {
  font-size: 24px;
  color: #999;
  text-align: center;
}

@media (max-width: 991px) {
  .form, .description {
    width: 100%;
  }
}
