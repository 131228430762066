.loader {
    background-color: rgba(255,255,255, .9);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
  }
  
  .loader h3 {
    color: #000;
    font-family: monospace;
    overflow: hidden; 
    border-right: .15em solid #1565c0; 
    white-space: nowrap; 
    margin: 0 auto; 
    letter-spacing: .15em; 
    animation: 
      typing 4.5s steps(30, end),
      blink-caret .5s step-end infinite;
  }
  
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: #1565c0 }
  }

  @media (max-width: 767px) {
    .loader h3 {
      animation: none;
      white-space: normal; 
      border-right: none;
      padding: 0 10px;
    }
  }
